.footer{
    width: 100%;
    padding: 15px 0;
    position: fixed;
    background: $color-white;
    border-top: solid 1px $color-green;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    &__logo{
        display: inline-block;
        img{
            height: 30px;
            width: auto;
        }
    }

    &__social{
        margin: auto;
        display: inline-block;
        margin-left: 15px;
        
        &__icon{
            background-color: $color-gray;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            color: $color-white !important;
            transition: .5s;
            margin: 0 2px;

            svg{
                width: 10px;
                height: 10px;
            }

            &:hover{
                background-color: $color-yellow;
            }
        }
    }

   &__mail{
       color: $color-green;
       float: right;
       display: flex;
       align-items: center;

       &:hover{
           color: $color-yellow;
           p{
               color: $color-yellow;
           }
       }

       p{
           display: inline-block;
           color: $color-gray;
           margin: 0;
           font-size: 16px;
       }

       svg{
           margin-top: 2px;
           margin-right: 5px;;
           height: 20px;
           width: 20px;
           display: inline-block;
       }
   }

    &__links{
        display: inline-flex;
        margin: auto auto auto 20px;
        span{

            color: $color-dark-green;
            font-size: 15px;
        }
        a{
            color: $color-green;
            font-size: 15px;

            &:hover{
                color: $color-yellow;
            }
        }
    }

   @include media-breakpoint-down(sm) {
    text-align: center;
    position: relative;
      &__mail{
          padding-top: 10px;
          float: none;
          margin: auto !important;
      }

      &__links{
            margin: 10px auto auto;
            flex-direction: column;
            display: flex;
            a{
                margin: auto;
            }
            span{
                display: none;
            }
      }
    }
}