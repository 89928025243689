.navigation{
    position: fixed;
    width: 100vw;
    top: 0;
    padding: 20px 0;
    transition: .5s;
    z-index: 9;
    background-color: rgba($color-black, $alpha: 0.3);


    &:after{
        content: "";
        height: 1px;
        width: 100vw;
        position: absolute;
        bottom: 0px;
        background-color: $color-white;
        display: block;
        transition: .5s;
    }

    &__bars{
        margin: auto 20px auto 0;
        transition: .5s;
        color: $color-green !important;
        padding-top: 5px;
        cursor: pointer;
        display: none;

        svg{
            height: 35px;
        }

        &:hover{
            color: $color-yellow !important;
        }
    }

    &__logo{
        margin: auto 20px auto 0;
        cursor: pointer;

        img{
            height: 35px;
            width: auto;
            transition: .5s;
        }
    }

    .cta{
        margin: auto 0 auto 20px;
        display: inline-flex;
        min-width: 175px;
    }

    &__content{
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        
        width: 100%;
        transition: .5s;

        &__menu{
            margin: auto;
            
            a{
                display: inline-flex;
                margin-right: 20px;
                cursor: pointer;

                p{
                    font-family: 'Arcon-Regular';
                    font-size: 14px;  
                    text-transform: uppercase;
                    color: $color-white;
                    transition: .5s;
                    margin-bottom: 0;

                    &:hover{
                        color: $color-yellow;
                    }
                }
            }
        }

        &__social{
            margin: auto;
            display: flex;
            flex-direction: column;
            position: fixed;
            right: 15px;
            bottom: 15px;
            align-items: center;
            z-index: 2;
            
            &__icon{
                background-color: $color-green;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: inline-flex;
                justify-content: space-around;
                align-items: center;
                color: $color-white !important;
                transition: .5s;
                margin: 3px 0;
                border: 2px solid white;

                svg{
                    width: 14px;
                    height: 14px;
                }

                &:hover{
                    background-color: $color-yellow !important;
                }
            }

            .whatsapp{
                display: inline-flex;
                width: 50px;
                height: 50px;
                background-color: $color-whatsapp;
                border-radius: 50%;
                padding: 10px;
                margin: 3px 0;
                border: 3px solid white;

                svg{
                    width: 100%;
                    height: auto;
                }
            }

        }
    }
    &--hide-cta{

        .navigation
        {    
            &__content{
                &__menu{
                    margin-right: 0 !important;
                }
            }

        }
    }

    &--fix-top{
        background-color: $color-white;
        padding: 10px 0;
        
        .cta{
            background-color: $color-green;
        }

        &:after{
            background-color: $color-yellow;
        }
        .navigation
        {

            &__logo{
                &:before{
                    background: $color-green;
                }

                svg{
                    &:first-child{
                        fill: $color-green;
                    }
                    &:last-child
                    {
                        fill: $color-dark-gray;
                    }
                }
            }

            &__content{   
                &__menu{
                    a{
                        p{
                            color: $color-green;
    
                            &:hover{
                                color: $color-yellow;
                            }
                        }
                    }
                }
            }
        }
    }



    @include media-breakpoint-down(lg) { 

        .cta{
            margin-left: auto;
        }

        &__bars{
            display: block;
        }

        &__content{
            display: block;
            position: fixed;
            background-color: white;
            top: 70px;
            left: 0;
            right: 0;            
            max-height: 0;
            padding: 0;
            overflow: hidden;

            &__menu{
                flex-direction: column;
                max-width: 100%;
                padding-top: 10px;

                a{
                    display: block;
                    width: 100%;
                    padding: 10px 0;
                    margin-left: 0;
                    
                    
                    p{
                        color: $color-gray;
                        text-align: center;
                    }
                }
            }
        }

        &--open{
            
            .navigation{
                
                &__bars{
                    color: $color-yellow !important;
                }

                &__content{
                    max-height: 500px;
                }
            }
        }

        &--fix-top{
            .navigation
            {
                &__content{   
                    top: 50px;

                     &__menu{
                        a{
                            p{
                                color: $color-green;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__content{
            &__social{
                &__icon{
                    display: none;
                }
            }
        }
    }
    

    @include media-breakpoint-down(xs) {
        .container{
            padding: 0 8px;
        }
        .cta{
            margin-right: 5px;
            padding: 0;
            p{
                margin: 7px 0px 5px;
            }
        }

        &__logo{
            margin-left: 5px;
            
            img{
                height: 25px;
            }

        }
        &__bars{
            margin-right: 5px;
            svg{
                height:  25px;
            }
        }
    }
}
    

