.icons{

    &__item{
        text-align: center;
        margin-top: 30px;
        img{
            max-width: 150px;
            margin: auto;
        }

        p{
            text-align: center;
            padding: 0 15px;
        }

        h3{
            margin-top:  25px;
            margin-bottom: 10px;
        }
    }
}