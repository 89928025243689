.contato{

    .container{
        padding-bottom: 20px;
    }

    h4{
        color: $color-dark-gray;
    }

    p{
        margin-bottom: 5px;
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
    }

    a{
        display: flex;
        justify-content: center;
        align-items: center;

    }

    svg{
        margin: auto 0;
        color: $color-green;
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }

    &__social{
        display: flex;
        justify-content: center;
        
        &__icon{
            background-color: $color-green;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            color: $color-white !important;
            transition: .5s;
            margin: 3px 0;
            border: 2px solid white;
            margin: auto 10px;

            svg{
                margin: auto;
                color: $color-white;
                width: 25px;
                height: 25px;
            }

            &:hover{
                background-color: $color-yellow !important;
            }
        }
    }

    iframe{
        width: 100%;
        height: 300px;
        border: 1px solid $color-yellow;
        margin: 20px 0;
    }

    @include media-breakpoint-down(lg) { 
        svg{
            width: 22px;
            height: 22px;
        }
    }

    @include media-breakpoint-down(md) { 
        svg{
            width: 22px;
            height: 22px;
        }
    }
    @include media-breakpoint-down(sm) { 
        iframe{
            height: 200px;
        }

        &__social{           
            &__icon{
                width: 30px;
                height: 30px;
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
}