.cookies{
    padding-top: 10px;
    position: fixed;
    background-color: $color-dark-green;
    border-top: 2px solid $color-yellow;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 100;
    pointer-events: none;

    col{
        display: flex;
    }

    &__texto{
        display: inline-block;
        width: auto;
        a{
            display: inline-block;
            text-decoration: underline !important;
            margin: 0 !important;
            padding: 0;
        }

        p,a{
            color: $color-white !important;
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    &__button{
        display: inline-flex;
        flex-direction: column;
        align-content: center;
        pointer-events: all;

        .cta{
            margin: auto;
            background-color: $color-yellow;
            text-decoration: none !important;
            
            p{
                color: $color-dark-gray;
            }

            &:hover{
                background-color: $color-light-green;
            }

            @include media-breakpoint-down(sm) {
                margin: 20px auto;
            }
        }
    }
}