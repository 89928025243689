.template{

    &--interna{
        background-color: $color-green;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 10rem 0 10rem;

        .section{
        }
        
        .container{
            margin: auto;
            background-color: $color-white;
        }

        .row{
            padding: 0 2rem;
        }

        @include media-breakpoint-down(sm) {
            min-height: 50vh;
            padding: 10rem 0 5rem;
        }
    }
}