.banner{
    &__principal{
        position: relative;

        &__imagem{
            position: absolute;
            object-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &__conteudo{
            padding-top: 120px;
            padding-bottom: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            .cta{
                margin: auto 0;
            }

            p{
                color: $color-white !important;
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }


        &--alt{
            display: flex;
            min-height: 100vh;
            .banner{
                &__principal{
                    align-items: center;
                    &__conteudo{
                        padding: 70px 0 100px;

                        margin: auto;
                        h1{
                            font-size: 45px;
                            color: $color-green;
                            text-align: center;
                        }

                        h2{
                            color: $color-white;
                            text-transform: none;
                            font-size: 28px;
                            font-weight: 300;
                        }
                        
                        h3{
                            color: $color-green;
                            font-weight: 900;
                            font-size: 28px;
                            margin: auto;
                        }

                        img{
                            height: 50px;
                            margin-top: 1px;
                            vertical-align: text-top;
                        }

                        p{
                            text-align: center;
                            margin: auto;
                        }

                        .rounded{
                            border-radius: 10px !important;
                        }
                    }

                    &__form{
                        padding-top: 50px;
                        padding-bottom: 30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        overflow: hidden;

                        h3{
                            color: $color-white;
                            background-color: $color-green;
                            display: inline-block;
                            width: auto;
                            margin: auto;
                            padding: 5px 40px;
                            border-radius: 40px;
                            font-size: 18px;
                            margin-bottom: 50px;
                            position: relative;

                            &:before{
                                content: "";
                                width: 0; 
                                height: 0; 
                                border-left: 30px solid transparent;
                                border-right: 30px solid transparent;
                                border-top: 30px solid $color-yellow;
                                position: absolute;
                                bottom: -45px;
                                left: 0;
                                right: 0;
                                margin: auto;
                                animation: bounce 2s infinite;
                            }
                        }
                        
                        &__campos{
                            padding: 35px 30px;
                            background-color: $color-green;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }

    &__sol{
        position: relative;

        p{
            color: $color-dark-gray;
            font-weight: 400;
            position: relative;
        }

        &__container{
            max-height: 500px;
            overflow: hidden;
            position: relative;
            transition: .5s;

            &--open{
                max-height: 50000px;
                
                &:after{
                    display: none !important;
                }
            }

            &:after{
                position: absolute;
                content: "Ler mais";
                width: 100%;
                height: 300px;
                background: linear-gradient(0, $color-white, transparent);
                bottom: 0;
                display: flex;
                align-items: flex-end;
                justify-content: space-around;
                font-size: 20px;
                font-family: 'Arcon-Regular';
                color: $color-dark-green;
                cursor: pointer;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
        
        &__imagem{
            position: absolute;
            object-position: center;
            object-fit: fill;
            height: 100%;
            width: 100%;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            opacity: 0.3;
            max-width: 1200px !important;
            max-height: 1200px !important;
        }
        svg{
            max-width: 50px;
            margin-bottom: 50px;
        }

        img{
            max-width: 300px;
            max-height: 100px;
            margin: auto;
            padding-bottom: 30px;
        }
    }
    
     @include media-breakpoint-down(lg) {
        &__principal{
            &--alt{
                .banner{
                    &__principal{
                        &__conteudo{
                            h1{
                                font-size: 40px;
                            }

                            h2{
                                font-size: 25px;
                            }
                            
                            h3{
                                font-size: 23px;
                            }

                            img{
                                height: 45px;
                                margin-top: 2px;
                            }
                        }

                        &__form{
                            padding-top: 120px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__principal{
            &--alt{
                .banner{
                    &__principal{
                        &__conteudo{
                            h1{
                                font-size: 40px;
                            }

                            h2{
                                font-size: 25px;
                                margin: 30px 0;
                            }
                            
                            h3{
                                font-size: 23px;
                                margin-top: 30px;
                            }

                            img{
                                height: 45px;
                                margin-top: 2px;
                            }
                        }

                        &__form{
                            padding-top: 0px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) { 
        &__principal{
            &__conteudo{
                text-align: center;
                
                .cta{
                    margin: auto;
                }
            }

            &--alt{
                .banner{
                    &__principal{
                        &__conteudo{
                            padding: 100px 15px 50px;

                            h1{
                                font-size: 35px;
                            }

                            h2{
                                font-size: 23px;
                            }
                            
                            h3{
                                font-size: 20px;
                            }

                            img{
                                height: 40px;
                            }
                        }

                        &__form{
                            &__campos{
                                border-radius: 0;
                                margin: 0 -15px;
                            }
                        }
                    }
                }
            }
        }
    }
}