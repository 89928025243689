.mario-penna{
    background-color: $color-white;
    padding-top: 5em;

    h2,h1,h3{
        font-size: 3em;
        text-align: left;
        font-weight: 900;
        font-family: 'Arial' !important;     
        text-transform: none;   

        @include media-breakpoint-down(xs) { 
            font-size: 2.5em;
        }
        color: $color-dark-blue-mp;
    }
    
    @include media-breakpoint-down(sm) {
        a.anchor {
            top: -90px;
        }
    } 

    p{
        text-align: left;
        font-size: 1.5em;
    }

    a{
        display: inline;
    }

    &__call{
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3{
            white-space: pre-line;
            width: auto;
            text-transform: uppercase;
        }

        .cta{
            p{
                color: $color-yellow;
                padding: 0 2rem;
                font-weight: 900;
                font-family: 'Arial';
                font-size: 2rem;
            }

            display: inline-block;
            background-color: $color-dark-blue-mp;
            border: none;
            border-radius: 50px;
            margin: auto;
            &:hover{
                background-color: $color-dark-blue-mp !important;
            }
        }

        @include media-breakpoint-down(sm) { 
            display:  block;
            text-align: center;

            h3{
                text-align: center;
            }

            .cta{
                margin: auto;
            }
        }
    }

    &__modal{
        justify-content: center;

        button{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 2rem;
            color: white;
            opacity: 1;
        }

        .modal-content{
            text-align: center;
            background: transparent;
            border: none;
            width: auto;
        }

        img{
            max-width: 100%;
            max-height: 94vh;
            width: auto;
            height: auto;
            cursor: pointer;
        }
    }
    
    &__header{
        .cta{
            p{
                color: $color-black !important;
            }
            background-color: $color-yellow;
            border: none;
            border-radius: 5px;

            &:hover{
                background-color: $color-green !important;
            }

            @include media-breakpoint-down(sm) { 
                min-width: 14rem;
                p{
                    font-size: 1rem;
                }
            }
        }

        .navigation__logo{
            img{
                margin: auto;
                height: 4rem;
                
                &:first-child{
                    height: 3rem;
                    margin-right: 1rem;
                }
            }
        }

        .navigation__content__menu{
            margin-right: 0;
            svg{
                margin-top: -.3rem;
                height: 1.3rem;
            }

            a > p{
                color: $color-black;
                &:hover{
                    color: $color-gradient-kpd-yellow-1;
                }
            }
        }


        @include media-breakpoint-down(sm) { 
            .navigation__content{
                top: 40px;
                max-height: 50px;
                border-bottom: 1px solid $color-green;
            }
            
            .navigation--open{
                .navigation__content{
                    max-height: 500px;
                }
            }
        }
        
    }

    &__banner{
        &__conteudo{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding-bottom: 3rem;
            position: relative;
            z-index: 1;

            h1{
                text-transform: uppercase;
            }
            h3{
                font-size: 1.5em;
                margin: 1em 0;
                text-transform: none;
            }

            p{
                line-height: 1.2em;
                font-size: 1.4em;
                margin-bottom: 1.4rem;
                
                &.big{
                    font-size: 1.8em;
                }
            }

            &--font-big{
                p{
                    font-size: 2em;
                }
            }

            &__whatsapp{
                display: flex !important;
                justify-content: space-around;
                p{
                    font-size: 1.6rem;
                }
                svg{
                    width: 4em;
                }
            }
        }

        &__imagem{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }



        &--alt{
            padding-bottom: 1rem;
            .mario-penna__imagem{
                text-align: left;
                position: relative;
                max-height: 100%;
    
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    z-index:0;
                    height: 100%;
                }

                @include media-breakpoint-down(sm) {     
                    margin-top: -5rem;                
                    img{
                        position: relative;

                    }
                }
            }

            p{
                color: $color-black !important;

                @include media-breakpoint-down(sm) {  
                    text-align: center;
                }
            }

        }
    }

    &__logos{
        display: flex;
        margin-top: 2em;
        margin-bottom: 2em;

        img{            
            &:first-child{
                width: 30%;
                margin-right: 4%;
                height: auto;
            }
            
            &:last-child{
                width: 40%;
                height: auto;
            }
        }

        @include media-breakpoint-down(sm) { 
            justify-content: space-around;
        }
    }

    &__sol{
        text-align: center;
        margin-top: 2rem;
        img{
            display: inline;
            width: 15rem;
            margin: auto;
        }
        h1{
            margin: 0;
            position: relative;
        }
        @include media-breakpoint-down(sm) { 
            margin-top: 9rem;
            img{
                display: none;
            }
        }
    }

    &__bg{
        position: relative;
        background: $color-green;

        &__imagem{
            position: absolute;
            object-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        @include media-breakpoint-down(md) {         
            padding-top: 4em;  
        }
    }

    &__form{
        padding-top: 4em;
        padding-bottom: 4em;

        &__header{
            background-color: $color-white;
            border-top-left-radius: 1.5em;
            border-top-right-radius: 1.5em;
            padding: 1.5em 3em;
            margin-bottom: -2.5em;

            p{
                text-align: center;
                font-size: 1.5em;
                font-weight: 900;
                color: $color-dark-gray;
            }
        }
        
        &__content {
            background-color: $color-yellow;
            border-radius: 1.5em;
            padding: 3em;
            
            .input__error{
                font-size: 12px;
                text-align: center;
            }

            .input--submit{
                background-color: $color-light-green;
                font-weight: 900;

                &:hover{
                    background-color: $color-white;
                    color: $color-dark-green;
                }
            }
        }

        @include media-breakpoint-down(md) {         
            padding-top: 0;  
        }
    }

    &__carrossel{
        
        padding: 4em 0;

        @include media-breakpoint-down(sm) {         
            padding: 4em 0 0;
        }

        &__intro{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            p{
                margin-bottom: 1rem;
            }
        }

        &__menu{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            &__item{
                padding: 0;
                
                img{
                    cursor: pointer;
                    max-width: 100%;
                }
            }

            @include media-breakpoint-down(sm) {         
                display: none;
            }
        }
        
        &__content{
            margin-top: 4em;
            position: relative;
            
            &__item{
                display: flex;
                height: 100%;
                align-items: center;
                min-height: 300px;
                padding-left: 6em;
                padding-right: 6em;

                h3{
                    color: $color-black;
                    margin-bottom:.3em;
                }

                p{
                    color: $color-dark-gray;
                    margin: 0;
                    line-height: 1.5;
                    margin-bottom: 3rem;
                }
                
                img{
                    margin: auto;
                    padding-right: 4em;
                    filter: brightness(0);
                    max-width: 40%;
                }

                @include media-breakpoint-down(md) {         
                    flex-direction: column;

                    h3{
                        text-align: center;
                    }

                    img{
                        max-width: 40%;
                        padding: 0;
                    }                 
                }

                @include media-breakpoint-down(sm) {     
                    min-height: 270px;    
                    h3{
                        margin: 1em 0;
                    }     
                    img{
                        max-width: 50%;
                    } 
                }
            }

            .owl-stage{
                display: flex;
            }

            .owl-item{
                &:nth-child(even)
                {   
                    .mrv__carrossel__content__item {
                        padding-right: 20% !important;
                    }
                }
                
                &:nth-child(odd)
                {
                    .mrv__carrossel__content__item {
                        padding-left: 20% !important;
                    }
                }

                @include media-breakpoint-down(md) {
                    &:nth-child(even), &:nth-child(odd)
                    {   
                        .mrv__carrossel__content__item {
                            padding: 4em 8em !important;
                        }
                    }
                }
            }

            .owl-nav{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 2em;
                right: 2em;
                height: 4em;
                margin: auto 0;
                pointer-events: none;

                .owl-next, .owl-prev{
                    pointer-events: all;
                    height: 4em;

                    &.disabled{
                        opacity: 50%;
                    }
                }
                .owl-next{
                    float: right;
                    transform: rotate(180deg);
                }
                .owl-prev{
                    float: left;
                }
            }
        }

        &--2{
            background-color: $color-green;
            padding: 3em 0;
            
            &__menu{
                margin-top: 4em;
                display: flex;
                justify-content: space-between;

                &__item{
                    width: 3em;
                    height: 3em;
                    background-color: $color-white;
                    border-radius: 50%;
                    font-size: 2em;
                    text-align: center !important;
                    padding-top: .8em;
                    margin-bottom: 1em;
                    cursor: pointer;
                    position: relative;
                    font-weight: 900;

                    &:after{
                        content: "";
                        position: absolute;
                        height: .5em;
                        left: 2.5em;
                        width: 6em;
                        background-color: $color-white;
                        pointer-events: none;
                        top:0;
                        bottom: 0;
                        margin: auto;
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                }
            }
            
            &__content{
                margin: 4em 1.5em;
                background-color: $color-white;
                position: relative;

                &__pointer{
                    position: absolute;
                    border: 2em solid $color-white;
                    border-right-color: transparent;
                    border-top-color: transparent;
                    top: -4em;
                    left: 0;
                }

                &__item{
                    padding: 4em 3em;
                    
                    
                    h3{
                        font-size: 2.5em;
                        margin-bottom: 1em;
                    }

                    p{
                        margin-bottom: 0;
                    }
                }

                .mario-penna__sol, .mario-penna__logos{
                    transform: scale(.8);
                }
            }

            @include media-breakpoint-down(md) { 

                &__menu{
                    &__item{
                        padding-top: .8em;
                        &:after{
                            width: 4em;
                        }
                    }
                }

                &__content{
                    .mario-penna__logos{
                        flex-direction: column;

                        img{
                           margin: 1rem; 
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) { 

                &__menu{
                    &__item{
                        width: 2.5em;
                        height: 2.5em;
                        padding-top: .6em;
                        &:after{
                            width: 2em;
                        }
                    }
                }

                &__content{
                    &__item{
                        h3{
                            margin-bottom: 1em;
                        }
                    }
                    .mrv__logos{
                        flex-direction: row;
                        margin-bottom: 2rem; 
                        img{
                           margin: 1rem; 
                        }
                    }
                }
            }

            @include media-breakpoint-down(xs) {         
                &__content{
                    &__item{
                        h3{
                            font-size: 1.6em;
                        }
                    }
                }

                &__menu{
                    &__item{
                        width: 2em;
                        height: 2em;
                        padding-top: .4em;
                        
                        &:after{
                            left: 2em;
                        }
                    }
                }
            }
        }
    }
}

.mario-penna-gratient{
    &--blue{
        background: $color-blue-mp;
    }
    &--yellow{
        background: $color-gradient-kpd-yellow-1;
    }
}
