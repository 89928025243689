.usinas{

    &__cta{
        background-color: $color-green;
    }

    &__header{
        background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2020/11/solatiobg.png);
        background-position: center;
        background-size: cover;
        padding: 220px 0 40px;

        img{
            width: 80%;
            height: auto;
            float: right;
        }

        h1{
           
        }
    }

    h3{
        color: $color-green;
        text-transform: none;
    }
    
    h2{
        color: $color-green;
        font-weight: normal;
    }

    &__item{
        margin: 40px 0;
        p{
            margin-bottom: 5px;
        }
        
        &__imagem{
            outline: 4px solid $color-green;
            width: 200px;
            height: 200px;
            object-fit: cover;
            object-position: center;
            margin-bottom: 10px;
        }

        &__nome{
            color: $color-green;
        }

        &__descricao{
            img{
                height: 20px;
                width: 20px;
                object-fit: contain;
                object-position: center;
                display: inline;
                margin-right: 10px;
            }
        }
    }

    @include media-breakpoint-down(sm) { 
        &__item{
            text-align: center;
        }

        &__header{
            padding: 120px 0 40px;

            img{
                float: none;
                width: 100px;
            }
        }
    }
}