.form{
    &__simulador
    {        
        margin-top: 50px;
        background-color: $color-dark-green;

        .cta{

            margin: auto;
            background-color: $color-green;
            
            &:hover{
            }
            h3{
                padding: 20px 35px;
                color: $color-white;
            }
        }

    }
}


.item-consumo{
    display: inline-block;
    text-align: center;
    
    p{
        text-align: center;
        padding: 10px 0;
        color: $color-white;
    }

    img{
        margin: auto;
        width: 75px !important;
        height: 75px  !important;
        display: block;
        border: solid 4px $color-white;
        border-radius: 50%;
    }
}

.modal{

    &-open{
        padding-right: 0 !important;
    }

    &-content{
        border-radius: 15px;
    }

    &-dialog{
        max-width: 600px !important;
    }

    &-body{
        padding: 0 75px;
    }
    
    &-header{
        border: none;

        .close{
            margin-left: -14px;
        }
    }
    
    &-title{
        text-align: center;
        width: 100%;
    }

    &__img{
        margin: auto;
        max-width: 180px;
        max-height: 180px;
        margin-top: -75px;
    }

    &__text{
        text-align: center;

        span{
            text-decoration: underline;
            color: $color-dark-green;
        }
    }



    &__buttons{
        padding-top: 30px;
        margin: 0 -130px -30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        

        .input{
            height: auto;
            line-height: 1.2;
            padding: 15px 30px;
            margin: 0 20px;
            white-space: nowrap;
        }

    }
    @include media-breakpoint-down(sm) {
        padding-top: 70px;

        &__buttons{
            padding-top: 0;
            padding-bottom: 15px;
            margin: 0;
            display: block;
            text-align: center;

            .input{
                display: inline-block;
                padding: 15px;
                margin: 10px 15px;
                width: auto;
            }
        }
    }
}

.share{
    margin: 20px auto;
    display: inline-block;
    
    &__icon{
        background-color: $color-green;
        border-radius: 50%;
        padding: 10px;
        display: inline-flex;
        justify-content: space-around;
        align-items: center;
        color: $color-white !important;
        transition: .5s;
        margin: 0 10px;

        svg{
            width: 30px;
            height: 30px;
        }

        &:hover{
            background-color: $color-yellow !important;
        }
    }
}

.tooltip{

    &-icon{
        border-radius: 50%;
        padding: 10px;
        display: inline-flex;
        justify-content: space-around;
        align-items: center;
        color: $color-dark-gray !important;
        transition: .5s;
        margin: 0 10px;
        position: absolute;
        right: 12px;
        top: -1px;
        svg{
            width: 20px;
            height: 20px;
        }
    }

    &-inner{
        max-width: 250px;
    }

    font-size: 14px;

}

.files-container{
    border: dashed white 3px;
    height: 100%;
    text-align: center;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    a { 
        text-decoration: none !important;
        color: inherit !important;
        display: inline;
        &:not([href]):not([tabindex]) {
            color: inherit !important;
        }
    
        &:hover, &:visited, &:active {
            text-decoration: inherit !important;
        }
    }

    p{
        text-align: center;
        margin: auto;
        text-transform: uppercase;
    }

    .react-html5-camera-photo {
        padding-bottom: 100px;

        img, video{
            max-width: 100%;   
            max-height: 80%;
        }
    }
}

.input-label{
    display: inline-flex;
    color: $color-white;
    font-size: 16px;
    width: auto;

    input[type=checkbox], input[type=radio]
    {
        width: 18px;
        height: 18px;
        margin: auto;
        display: inline;
        position: relative;
        vertical-align: middle;
        margin-right: 10px;
    }

    p{
        display: inline;
        margin: auto;
        vertical-align: middle;
        line-height: .6;
        text-align: left;
        
        small{
            vertical-align: middle;
            font-size: 11px;
        }

        a{
            color: inherit;
            transition: .5s;
            &:hover{
                color: $color-dark-green;
            }
        }
    }
}

.inputfile
{
    position: relative;
    margin: 1rem auto 1.5rem;
    color: $color-dark-gray;
    background-color: $color-yellow;
    padding: .5rem 3rem;
    border: none !important;
    transition: .5s;
    border-radius: 3px;
    cursor: pointer;
    width: auto;
    font-size: 1.6rem;
    text-transform: uppercase;
    overflow: hidden;
    
    &:hover, &:focus{
        background-color: $color-white;
    }
    
    input[type=file]
    {
        cursor: pointer;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
    }    
}

.input{

    display: block;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px $color-dark-green;
    padding: 5px 10px;
    color: $color-dark-gray;
    font-family: 'Arcon-Rounded-Regular';
    font-size: 16px;
    outline: none !important;
    
    & + p, .msg-erro{
        padding-bottom: 10px;
        margin: 0;
        font-size: 14px;
        text-align: center;
        height: 18px;
        margin-bottom: 5px;

        span{
            display: none;
        }
    }

    &:focus{
        box-shadow: none !important;
        outline: none !important;
    }


    &--submit{

        @include media-breakpoint-down(sm) {
            display: block;
            & + .input--submit{
                margin-top: 15px;
            }
        }

        color: $color-dark-gray;
        background-color: $color-yellow;
        padding: 5px 30px;
        border: none !important;
        transition: .5s;

        &:hover{
            background-color: $color-dark-green;
            color: $color-white;
        }

        &--big{
            font-size: 25px;
            height: 55px;
            &:hover{
                background-color: $color-green;
            }
        }
    }

    &.is-valid{
        padding-right: 55px;     
        & + p, .msg-erro{
            position: relative;
        }
    }

    &.is-invalid{
        border: solid 1px $color-red; 
        padding-right: 55px;       
        & + p, .msg-erro{
            span{
                display: block;
            }
        }
    }

    &__error{
        color: $color-red;
    }
}



.disabled-input{
    opacity: 0.8;
    cursor: not-allowed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}