.itatiaia{

    &-call{
        display: flex;
        justify-content: space-around;

        h3{
            font-size: 2rem;
            width: auto;
            margin: auto;
        }

        @include media-breakpoint-down(sm) {
            display: block;
            h3{
                text-align: center;
            }
        }
    }

    .mrv__banner--alt{

        img{
            margin: auto;
            width: 100%;
            height: auto;
            border-radius: 2rem;
        }
    }
 
    .mrv__bg{
        background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/05/itatiaia-bg-2.svg),
        linear-gradient(to left, $color-gradient-itatiaia-1, $color-gradient-itatiaia-2);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
    }

    .mrv__logos{
        align-items: center;

        img{
            margin-top: auto;
            margin-bottom: auto;
            max-height: 5rem;
            margin-right: 2em;
        }
    }

    .mrv__carrossel__menu {
        align-items: center;

        &__item{
            img{
                max-height: 80%;
                margin: 10%;
            }
        }
    }

    .cta{
        background-color: $color-green;
        display: block;
        min-width: 18rem;
    }

    .mrv__banner--alt{
        h3{
            padding: 4rem 0 !important;
        }
    }
}
