.videos{
    &__grande{
        background-color: #fcfbf6;
        

        video{
            max-width: 100%;
            max-height: calc(100vh - 120px);
            width: auto;
            height: auto;
            outline: none !important;
        }

        h3{
            margin-top: -40px;
            margin-bottom: 20px;
            color: $color-white;
        }
    }

    &__stage{
        position: relative;
        padding-bottom: 50.6%;
        max-width: 90%;
        margin-left: 5%;

        iframe{
            position: absolute;
            top: 0; bottom: 0; left: 0; right: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__play-btn{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 200px;
        width: 200px;
        cursor: pointer;
        display: block;
    }

    @include media-breakpoint-down(sm) { 
        &__stage{
            padding-bottom: 56.25%;
            max-width: 100%;
            margin-left: 0;
        }
    }

    @include media-breakpoint-down(sm) { 
        &__play-btn{
            height: 100px;
            width: 100px;
        }
    }
}

.video-alt{
    display: none;
    padding-bottom: 50px;
    @include media-breakpoint-down(sm) {
        display: block;
    }
}