﻿$color-black: #000000;
$color-white: #ffffff;
$color-light-gray: #FAFAFA;
$color-gray: #B8BEC4;
$color-medium-gray: #7a7a7a;
$color-dark-gray: #54595F;
$color-cream: #fcfbf6;
$color-green: #5EC7D0;
$color-yellow: #FDD936;
$color-light-green: #64d8c1;
$color-dark-green: #0391ab;
$color-red: #B30C0C;
$color-whatsapp: #25D366;

$color-green-mrv-1: #81ea5c;
$color-green-mrv-2: #00d38b;
$color-pink-mrv-1: #ff59ac;
$color-pink-mrv-2: #f6297d;
$color-yellow-mrv-1: #ffb71a;
$color-yellow-mrv-2: #ffdc00;
$color-purple-mrv-1: #aa40d8;
$color-purple-mrv-2: #784098;

$color-green-itatiaia-1: #cdf0e3; 
$color-green-itatiaia-2: #68d3ac; 
$color-green-itatiaia-3: #74C2A5; 
$color-green-itatiaia-4: #099461; 
$color-yelllow-itatiaia-1: #fee780; 
$color-yelllow-itatiaia-2: #FFF200; 
$color-blue-itatiaia-1: #497586; 
$color-blue-itatiaia-2: #395c6b; 
$color-gradient-itatiaia-1: #00A099; 
$color-gradient-itatiaia-2: #62C1CD; 

$color-gradient-kpd-blue-1: #63C2CE; 
$color-gradient-kpd-blue-2: #8EE6ED; 
$color-gradient-kpd-yellow-1: #FAAF3B; 
$color-gradient-kpd-yellow-2: #FED934; 

$color-blue-mp: #48bdd7; 
$color-dark-blue-mp: #233873; 

$color-pink-luggo-1: #ff4fff; 
$color-pink-luggo-2: #e700be;
$color-yellow-luggo-1: #fbb714;
$color-yellow-luggo-2: #fb8b24;
$color-purple-luggo-1: #d0a0ef;
$color-purple-luggo-2: #b902f9;
$color-purple-luggo-3: #7813aa;
$color-purple-luggo-4: #3d005d;


$theme-colors: (
  'black':	$color-black,
  'white':	$color-white,
  'cream':	$color-cream,
  'gray':	$color-gray,
  'gray-light':	$color-light-gray,
  'gray-medium-gray':	$color-medium-gray,
  'gray-dark-gray':	$color-dark-gray,
  'red':	$color-red,
  'yellow':	$color-yellow,
  'green':	$color-green,
  'green-dark':	$color-dark-green,
  'green-light,': $color-light-green,
  "whatsapp": $color-whatsapp,
   "green-mrv-1": $color-green-mrv-1,
   "green-mrv-2": $color-green-mrv-2,
   "pink-mrv-1": $color-pink-mrv-1,
   "pink-mrv-2": $color-pink-mrv-2,
   "yellow-mrv-1": $color-yellow-mrv-1,
   "yellow-mrv-2": $color-yellow-mrv-2,
   "purple-mrv-1": $color-purple-mrv-1,
   "purple-mrv-2": $color-purple-mrv-2,
   "pink-luggo-1": $color-pink-luggo-1,
   "pink-luggo-2": $color-pink-luggo-2,
   "yellow-luggo-1": $color-yellow-luggo-1,
   "yellow-luggo-2": $color-yellow-luggo-2,
   "purple-luggo-1": $color-purple-luggo-1,
   "purple-luggo-2": $color-purple-luggo-2,
   "purple-luggo-3": $color-purple-luggo-3,
   "purple-luggo-4": $color-purple-luggo-4,
    "green-itatiaia-1": $color-green-itatiaia-1, 
    "green-itatiaia-2": $color-green-itatiaia-2, 
    "green-itatiaia-3": $color-green-itatiaia-3,
    "green-itatiaia-4": $color-green-itatiaia-4,
    "yellow-itatiaia-1": $color-yelllow-itatiaia-1,
    "yellow-itatiaia-2": $color-yelllow-itatiaia-2,
    "blue-itatiaia-1": $color-blue-itatiaia-1,
    "blue-itatiaia-2": $color-blue-itatiaia-2,
    "gradient-itatiaia-1": $color-gradient-itatiaia-1,
    "gradient-itatiaia-2": $color-gradient-itatiaia-2,
    "kdp-blue": $color-gradient-kpd-blue-1,
    "kdp-yellow": $color-gradient-kpd-yellow-1,
    "mario-penna-blue": $color-blue-mp,
    "mario-penna-blue-dark": $color-dark-blue-mp,
);




@mixin hover-focus {
    &:hover,
    &:focus {
        @content;
    }
}

@mixin custom-bg-variant($parent, $value) {
    #{$parent} {
        background-color: $value !important;
    }

    a#{$parent},
    button#{$parent} {
    
        @include hover-focus {
            background-color: darken($value, 10%) !important;
        }
    }
}

@mixin custom-color-variant($parent, $value) {
    #{$parent} {
        color: $value  !important;

        h1,h2,h3,h4,p{
            color: $value !important;
        }
    }
}

@each $color, $value in $theme-colors {
    @include custom-color-variant('.color-#{$color}',$value);
    @include custom-bg-variant('.bg-#{$color}', $value);
}
