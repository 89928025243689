.kdp{
    background-color: $color-white;
    padding-top: 8em;

    h2,h1,h3{
        font-size: 3em;
        text-align: left;
        font-weight: 900;
        font-family: 'Arcon-Regular' !important;

        @include media-breakpoint-down(xs) { 
            font-size: 2.5em;
        }
    }

    p{
        text-align: left;
        font-size: 1.6rem;
        line-height: 1.6;
    }

    a{
        display: inline;
    }

    &__call{
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) { 
            display:  block;
        }

        h3{
            white-space: pre-line;
            color: $color-white;
            width: auto;
        }

        .cta{
            p{
                color: $color-black;
                padding: 0 2rem;
            }
            display: inline-block;
            background-color: $color-gradient-kpd-yellow-1;
            border: none;
            border-radius: 5px;
        }
    }

    &__modal{
        justify-content: center;

        button{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 2rem;
            color: white;
            opacity: 1;
        }

        .modal-content{
            text-align: center;
            background: transparent;
            border: none;
            width: auto;
        }

        img{
            max-width: 100%;
            width: auto;
            height: auto;
            cursor: pointer;
        }
    }

    &__banner{
        &__conteudo{
            @include media-breakpoint-down(sm) { 
                padding-top: 5rem;
            }

            display: flex;
            flex-direction: column;

            h1{
                color: $color-gradient-kpd-yellow-1;
                font-size: 3.5rem;
            }

            h3{
                color: $color-black;
                text-align: left;
                text-transform: none;
                font-size: 2.5rem;
                margin: 1.8rem 0;
            }

            h2{
                color: $color-black;
                margin-bottom: 3rem;
            }

            .font-big{
                font-size: 2rem;
            }
        }

        &__imagem{
            text-align: center;
            position: relative;
            img{
                position: absolute;
                left: -2rem;
                width: 100%;
                max-width: 700px;
            }

            @include media-breakpoint-down(sm) { 
                display: none;
            }
        }

        &--alt{
            padding-top: 5rem;
            &__imagem{            


                &--1{
                    width: 95%;
                    margin-top: -6rem;
                    margin-bottom: -12rem;
                    @include media-breakpoint-down(sm) { 
                        display: none;
                    }
                }
                &--2{
                    width: 100%;
                    @include media-breakpoint-down(sm) { 
                        display: none;
                    }
                }

            }
            
            h2{
                font-size: 4rem;
            }

            p{
                color: $color-black;
            }
            
            @include media-breakpoint-down(sm) {         
                h3{
                    
                    padding: 2rem 0;
                }
            }
        }
    }

    &__logos{
        display: flex;
        justify-content: center;

        img{
            max-width: 13rem;
            margin-right: 2rem;
            
            &:last-child{
                margin-left: 2rem;
                max-width: 10rem;

            }
        }

        @include media-breakpoint-down(sm) {
            img{
                max-width: 35%;
            }
        }
    }

    &__sol{
        display: flex;

        img{
            margin: auto;
            max-width: 13rem;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

    }

    &__bg{
        position: relative;
        background: linear-gradient(45deg, $color-green, $color-dark-green);

        &__imagem{
            position: absolute;
            object-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        @include media-breakpoint-down(md) {         
            padding-top: 4rem;  
        }
    }

    &__form{
        padding-top: 4rem;
        padding-bottom: 4rem;
        
        &>div{
            box-shadow: 8px 8px 0 rgba(0,0,0,.3);
            border-radius: 1.5rem;
        }

        &__header{
            background-color: $color-white;
            border-top-left-radius: 1.5rem;
            border-top-right-radius: 1.5rem;
            padding: 1.5rem 3rem;
            margin-bottom: -2.5em;

            p{
                text-align: center;
                font-size: 1.5rem;
                line-height: 1;
                color: $color-black;
            }
        }
        
        &__content {
            background-color: $color-light-green;
            border-radius: 1.5rem;
            padding: 3em;
            
            .input__error{
                font-size: 12px;
                text-align: center;
            }
        }

        @include media-breakpoint-down(md) {         
            padding-top: 0;  
        }
    }

    &__carrossel{
        
        padding: 4em 0;

        &__intro{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            p{
                color: $color-black;
            }
        }

        &__menu{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            &__item{
                padding: 0;
                
                img{
                    cursor: pointer;
                    max-width: 100%;
                }
            }

            @include media-breakpoint-down(sm) {         
                display: none;
            }
        }
        
        &__content{
            margin-top: 4rem;
            position: relative;
            
            &__item{
                display: flex;
                height: 100%;
                align-items: center;
                min-height: 300px;
                padding-left: 6rem;
                padding-right: 6rem;

                h3{
                    color: $color-black;
                    margin-bottom:1rem;
                }

                p{
                    color: $color-dark-gray;
                    margin: 0;
                }
                
                img{
                    margin: auto;
                    padding-right: 4rem;
                    filter: brightness(0);
                    max-width: 40%;
                }

                @include media-breakpoint-down(md) {         
                    flex-direction: column;

                    h3{
                        text-align: center;
                    }

                    img{
                        max-width: 40%;
                        padding: 0;
                    }                 
                }

                @include media-breakpoint-down(sm) {     
                    min-height: 270px;    
                    h3{
                        margin: 1em 0;
                    }     
                    img{
                        max-width: 50%;
                    } 
                }
            }

            .owl-item{
                height: 100%;
                &:nth-child(even)
                {   
                    .mrv__carrossel__content__item {
                        padding-right: 20% !important;
                    }
                }
                
                &:nth-child(odd)
                {
                    .mrv__carrossel__content__item {
                        padding-left: 20% !important;
                    }
                }

                @include media-breakpoint-down(md) {
                    &:nth-child(even), &:nth-child(odd)
                    {   
                        .mrv__carrossel__content__item {
                            padding: 4rem 8rem !important;
                        }
                    }
                }
            }

            .owl-nav{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 2rem;
                right: 2rem;
                height: 4rem;
                margin: auto 0;
                pointer-events: none;

                .owl-next, .owl-prev{
                    pointer-events: all;
                    height: 4rem;

                    &.disabled{
                        opacity: 50%;
                    }
                }
                .owl-next{
                    float: right;
                    transform: rotate(180deg);
                }
                .owl-prev{
                    float: left;
                }
            }
        }

        &--2{

            padding: 3rem 0;
            
            &__menu{
                margin-top: 4rem;
                display: flex;
                justify-content: space-between;
                position: relative;
                
                &__item{
                    position: relative;
                    width: 4rem;
                    height: 4rem;
                    background-color: $color-white;
                    border-radius: 50%;
                    font-size: 2rem;
                    text-align: center !important;
                    padding-top: .8rem;
                    margin-bottom: 1rem;
                    cursor: pointer;
                    position: relative;
                    font-weight: 900;
                    z-index: 2;
                }

                &:before{
                    z-index: 1;
                    content: "";
                    position: absolute;
                    height: .5rem;
                    width: 95%;
                    background-color: $color-white;
                    pointer-events: none;
                    top:0;
                    right: 0;
                    left: 0;
                    bottom: .5rem;
                    margin: auto;
                }
            }
            
            &__content{
                margin: 4rem 1.5rem;
                background-color: $color-white;
                position: relative;

                &__pointer{
                    position: absolute;
                    border: 2rem solid $color-white;
                    border-right-color: transparent;
                    border-top-color: transparent;
                    top: -4rem;
                    left: 0;
                }

                &__item{
                    padding: 4rem 3rem;
                    
                    
                    h3{
                        font-size: 2.5rem;
                        margin-bottom: 1rem;
                    }

                    p{
                        margin-bottom: 0;
                    }
                }

                .mrv__sol, .mrv__logos{
                    transform: scale(.8);
                }
            }

            @include media-breakpoint-down(md) { 

                &__menu{
                    &__item{
                        padding-top: .8rem;
                        &:after{
                            width: 8rem;
                        }
                    }
                }

                &__content{
                    .mrv__logos{
                        flex-direction: column;

                        img{
                           margin: 1rem; 
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) { 

                &__menu{
                    &__item{
                        width: 3rem;
                        height: 3rem;
                        padding-top: .2rem;
                    }
                }

                &__content{
                    &__item{
                        h3{
                            margin-bottom: 1rem;
                        }
                    }
                    .mrv__logos{
                        flex-direction: row;
                        margin-bottom: 2rem; 
                        img{
                           margin: 1rem; 
                        }
                    }
                }
            }

            @include media-breakpoint-down(xs) {         
                &__content{
                    &__item{
                        h3{
                            font-size: 1.6rem;
                        }
                    }
                }                
            }
        }
    }

    &__header{
        .cta{
            p{
                color: $color-black;
            }
            background-color: $color-gradient-kpd-yellow-1;
            border: none;
            border-radius: 5px;
        }

        .navigation__logo{
            img{
                margin: auto;
                height: 3rem;

                &:first-child{
                    height: 6rem;
                    margin-right: 2rem;
                }
            }
        }
        .navigation__content__menu{
            svg{
                margin-top: -.3rem;
                height: 1.3rem;
            }

            a > p{
                color: $color-black;
                &:hover{
                    color: $color-gradient-kpd-yellow-1;
                }
            }
        }
    }
}

.kdp-gratient{
    &--blue{
        background: linear-gradient(45deg, $color-gradient-kpd-blue-1, $color-gradient-kpd-blue-2);
    }
    &--yellow{
        background: linear-gradient(45deg, $color-gradient-kpd-yellow-1,$color-gradient-kpd-yellow-2);
    }
}
