.duvidas-acordeon{
    background-color: $color-dark-green;
    margin-bottom: 5rem;

    &__title{
        h2,p{
            color: $color-white;
            text-align: center;
        }
    }

    &__panel--faq {
        transition: .25s ease;
        background-color: $color-light-gray !important;
        overflow: visible !important;

        a{
            font-size: 20px;
            color: $color-dark-green !important;
            font-family: 'Arcon-Rounded-Regular';
            display: inline;
        }

        p{
            font-size: 20px;
            color: $color-dark-gray;
            margin-bottom: 0;
            padding: 16px 0;
            font-family: 'Arcon-Rounded-Regular';
        }
        &:last-of-type{
            margin-bottom: 20px;
        }

        &:before{
            content: "";
            position: absolute; 
            width: 100vw;
            height: 100%;
            top: 0;
            background-color: $color-light-gray;
            right: calc(100% - 5px);
        }
    }
    
    &__panel{
        background-color: $color-dark-green;
    }

    &__header, &__header--faq{
        display: inline-flex;
        align-items: center;       
        position: relative;
        margin-bottom: 10px;
        margin-top: 10px;
        width: auto;
        border: none !important;
        outline: none !important;

        &:before{
            content: "";
            position: absolute; 
            width: 100vw;
            height: 100%;
            top: 0;
            background-color: $color-yellow;
            right: calc(100% - 15px);
        }

        &:after
        {
            content: "+";
            text-align: center;
            width: 12px;
            height: 12px; 
            margin: 10px 15px 10px 10px;
            font-size: 16px;
            line-height: .8rem;
            transition: .25s ease;
            color: $color-dark-gray;
            outline: 1px solid $color-dark-gray;
            transform: scale(2);
            margin-left: -50px;
        }

        h3, h4{
            padding: 15px 100px 15px 30px;
            width: auto;
            text-shadow: none;
            transition: .5s;
            text-align: left;
            background-color: $color-yellow;
        }
        h4{
            background-color: $color-green;
        }

        &:hover{
            h3,h4{
                padding-left: 60px;
            }
        }

        &[aria-expanded="true"]{
            &:after{
                transform: scale(2) rotate(45deg);
            }
        }
    }

     &__header--faq{
        &:before{
            background-color: $color-green;
        }

        &:after
        {
            color: $color-white;
            outline: 1px solid $color-white;
            transform: scale(1.6);
        }

        &[aria-expanded="true"]{
            &:after{
                transform: scale(1.6) rotate(45deg);
            }
        }
     }

     @include media-breakpoint-down(sm) {
         margin-bottom: 0;
     }
}
