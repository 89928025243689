﻿
html{
    font-size: 12px;
    line-height: initial;
    font-family: 'Arcon-Rounded-Regular';
    max-width: 100vw;
    overflow-x: hidden;
    text-size-adjust: none !important;

    @include media-breakpoint-down(lg) {
        font-size: 10px;
    }

    @include media-breakpoint-down(md) {
        font-size: 9px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 8px;
    }
}
body {
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
    background: $color-light-gray;
    text-size-adjust: none !important;
    font-family: 'Arcon-Rounded-Regular';
}

#root-react{
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

a {
    font-family: inherit;
    text-decoration: none !important;
    text-align: center;
    display: flex;
    width: fit-content;
    height: fit-content;

    &:not([href]):not([tabindex]) {
        color: inherit;
    }

    &:hover, &:visited, &:active {
        text-decoration: inherit !important;
    }
}

a.anchor {
    display: block;
    position: relative;
    top: -50px;
    text-align: center;
    visibility: visible;
    margin: auto;
}

p, h1, h2, h3, h4, h5, h6 {
    display: block;
    width: 100%;
    margin: 0;
    color: $color-white;
}

p {
    font-family: 'Arcon-Rounded-Regular';
    font-size: 22px;
    text-align: left;
    margin-bottom: 30px;
    line-height: 1.3;
    color: $color-medium-gray;

    @include media-breakpoint-down(lg) {
        font-size: 22px;
    }

    @include media-breakpoint-down(md) {
        font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
        text-align: center;
        font-size: 18px;
    }

    &.small{
        font-size: 14px !important;
    }
}

h1 {
    font-family: 'Arcon-Regular';
    text-transform: uppercase;    
    text-align: left;
    font-size: 50px;
    letter-spacing: .2rem;
    color: $color-white;
    line-height: 1.2;
    margin: auto;

    span{
        color: $color-yellow;
    }
    @include media-breakpoint-down(md) { 
        font-size: 35px;
    }        

    @include media-breakpoint-down(sm) {
        font-size: 30px;
        text-align: center;
    }
}

h2 {
    font-family: 'Arcon-Regular';
    text-transform: uppercase;    
    text-align: center;
    font-size: 40px;
    color: $color-dark-gray;
    font-weight: 900;

    @include media-breakpoint-down(md) {
        font-size: 30px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 25px;
        text-align: center;
    }
}

h3, h4 {
    font-family: 'Arcon-Regular';
    text-transform: uppercase;    
    text-align: center;
    font-size: 25px;
    color: $color-dark-gray;
    font-weight: bold;


    @include media-breakpoint-down(md) {
        font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
        text-align: center;
    }
}

h4 {
    font-size: 20px;
    color: $color-white;
    @include media-breakpoint-down(md) {
        font-size: 18px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
}

h5 {
    font-family: 'Arcon-Regular';
    font-size: 20px;
    text-align: center;
    color: $color-white;
}

.break-text{
    white-space: pre-wrap !important; 

    @include media-breakpoint-down(sm) {
        white-space: normal  !important; 
    }
}

.cta{
    border: 1px solid $color-white;
    transition: .5s;
    cursor: pointer;

    p{
        margin: 10px;
        font-family: 'Arcon-Regular';
        font-size: 14px;  
        text-transform: uppercase;
        color: $color-white;
        transition: .5s;
    }

    &:hover{
        background-color: $color-yellow !important;
        p{
            color: $color-white !important;
        }
    }
}



.modal-body{
    @include media-breakpoint-down(sm) {
        padding: 0 15px !important;
    }
}


.color-black{
    color: $color-black !important;

}
.color-white{
    color: $color-white !important;
}
.color-light-gray{
    color: $color-light-gray !important;
}
.color-gray{
    color: $color-gray !important;
}
.color-medium-gray{
    color: $color-medium-gray !important;
}
.color-dark-gray{
    color: $color-dark-gray !important;
}
.color-cream{
    color: $color-cream !important;
}
.color-green{
    color: $color-green !important;
}
.color-yellow{
    color: $color-yellow !important;
}
.color-light-green{
    color: $color-light-green !important;
}
.color-dark-green{
    color: $color-dark-green !important;
}
.color-red{
    color: $color-red !important;
}

.bg-color-black{
    background-color: $color-black !important;
}
.bg-color-white{
    background-color: $color-white !important;
}
.bg-color-light-gray{
    background-color: $color-light-gray !important;
}
.bg-color-gray{
    background-color: $color-gray !important;
}
.bg-color-medium-gray{
    background-color: $color-medium-gray !important;
}
.bg-color-dark-gray{
    background-color: $color-dark-gray !important;
}
.bg-color-cream{
    background-color: $color-cream !important;
}
.bg-color-green{
    background-color: $color-green !important;
}
.bg-color-yellow{
    background-color: $color-yellow !important;
}
.bg-color-light-green{
    background-color: $color-light-green !important;
}
.bg-color-dark-green{
    background-color: $color-dark-green !important;
}
.bg-color-red{
    background-color: $color-red !important;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
  transform-origin: center;
}