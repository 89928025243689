.mrv{
    background-color: $color-white;
    padding-top: 8em;

    h2,h1,h3{
        font-size: 3em;
        text-align: left;
        font-weight: 900;
        font-family: 'Arcon-Regular' !important;

        @include media-breakpoint-down(xs) { 
            font-size: 2.5em;
        }
    }

    p{
        text-align: left;
        font-size: 1.5em;
    }

    a{
        display: inline;
    }

    &__modal{
        justify-content: center;

        button{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 2rem;
            color: white;
            opacity: 1;
        }

        .modal-content{
            text-align: center;
            background: transparent;
            border: none;
            width: auto;
        }

        img{
            max-width: 100%;
            max-height: 94vh;
            width: auto;
            height: auto;
            cursor: pointer;
        }
    }

    &__banner{
        &__conteudo{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }

        &__imagem{
            text-align: center;
            img{
                width: 100%;
                max-width: 500px;
            }
        }

        &--alt{

            img{
                margin-left: 10vw;
                width: 40vw;
                height: 35vw;
                object-position: center bottom;
                object-fit: cover;

            }
            
            h3{
                text-align: center;
                font-size: 2.5em;
                padding: 4em 0;
            }
            
            @include media-breakpoint-down(sm) {         
                img{
                    width: 150vw;
                    height: 20em;
                    margin-left: -12em;
                }
                
                h3{
                    
                    padding: 2em 0;
                }
            }
        }
    }

    &__logos{
        display: flex;

        img{
            max-width: 40%;
            max-width: 15em;
            margin-right: 2em;
        }

        @include media-breakpoint-down(sm) {
            img{
                max-width: 35%;
            }
        }
    }

    &__sol{
        display: flex;

        img{
            margin: auto;
            max-width: 15em;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

    }

    &__bg{
        position: relative;
        background: linear-gradient(45deg, $color-green, $color-dark-green);

        &__imagem{
            position: absolute;
            object-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        @include media-breakpoint-down(md) {         
            padding-top: 4em;  
        }
    }

    &__form{
        padding-top: 4em;
        padding-bottom: 4em;

        &__header{
            background-color: $color-yellow;
            border-top-left-radius: 1.5em;
            border-top-right-radius: 1.5em;
            padding: 1.5em 3em;
            margin-bottom: -2.5em;

            p{
                text-align: center;
                font-size: 1.5em;
                font-weight: 900;
                color: $color-dark-green;
            }
        }
        
        &__content {
            background-color: $color-light-green;
            border-radius: 1.5em;
            padding: 3em;
            
            .input__error{
                font-size: 12px;
                text-align: center;
            }
        }

        @include media-breakpoint-down(md) {         
            padding-top: 0;  
        }
    }

    &__carrossel{
        
        padding: 4em 0;

        &__intro{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }

        &__menu{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            &__item{
                padding: 0;
                
                img{
                    cursor: pointer;
                    max-width: 100%;
                }
            }

            @include media-breakpoint-down(sm) {         
                display: none;
            }
        }
        
        &__content{
            margin-top: 4em;
            position: relative;
            
            &__item{
                display: flex;
                height: 100%;
                align-items: center;
                min-height: 300px;
                padding-left: 6em;
                padding-right: 6em;

                h3{
                    color: $color-yellow;
                    margin-bottom:1em;
                }

                p{
                    color: $color-white;
                    margin: 0;
                }
                
                img{
                    margin: auto;
                    padding-right: 4em;
                    filter: brightness(100000);
                    max-width: 40%;
                }

                @include media-breakpoint-down(md) {         
                    flex-direction: column;

                    h3{
                        text-align: center;
                    }

                    img{
                        max-width: 40%;
                        padding: 0;
                    }                 
                }

                @include media-breakpoint-down(sm) {     
                    min-height: 270px;    
                    h3{
                        margin: 1em 0;
                    }     
                    img{
                        max-width: 50%;
                    } 
                }
            }

            .owl-item{
                height: 100%;
                &:nth-child(even)
                {   
                    .mrv__carrossel__content__item {
                        padding-right: 20% !important;
                    }
                }
                
                &:nth-child(odd)
                {
                    .mrv__carrossel__content__item {
                        padding-left: 20% !important;
                    }
                }

                @include media-breakpoint-down(md) {
                    &:nth-child(even), &:nth-child(odd)
                    {   
                        .mrv__carrossel__content__item {
                            padding: 4em 8em !important;
                        }
                    }
                }
            }

            .owl-nav{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 2em;
                right: 2em;
                height: 4em;
                margin: auto 0;
                pointer-events: none;

                .owl-next, .owl-prev{
                    pointer-events: all;
                    height: 4em;

                    &.disabled{
                        opacity: 50%;
                    }
                }
                .owl-next{
                    float: right;
                    transform: rotate(180deg);
                }
                .owl-prev{
                    float: left;
                }
            }
        }

        &--2{

            padding: 3em 0;
            
            &__menu{
                margin-top: 4em;
                display: flex;
                justify-content: space-between;

                &__item{
                    width: 3em;
                    height: 3em;
                    background-color: $color-white;
                    border-radius: 50%;
                    font-size: 2em;
                    text-align: center !important;
                    padding-top: .8em;
                    margin-bottom: 1em;
                    cursor: pointer;
                    position: relative;
                    font-weight: 900;

                    &:after{
                        content: "";
                        position: absolute;
                        height: .5em;
                        left: 2.5em;
                        width: 7em;
                        background-color: $color-white;
                        pointer-events: none;
                        top:0;
                        bottom: 0;
                        margin: auto;
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                }
            }
            
            &__content{
                margin: 4em 1.5em;
                background-color: $color-white;
                position: relative;

                &__pointer{
                    position: absolute;
                    border: 2em solid $color-white;
                    border-right-color: transparent;
                    border-top-color: transparent;
                    top: -4em;
                    left: 0;
                }

                &__item{
                    padding: 4em 3em;
                    
                    
                    h3{
                        font-size: 2.5em;
                        margin-bottom: 1em;
                    }

                    p{
                        margin-bottom: 0;
                    }
                }

                .mrv__sol, .mrv__logos{
                    transform: scale(.8);
                }
            }

            @include media-breakpoint-down(md) { 

                &__menu{
                    &__item{
                        padding-top: .8em;
                        &:after{
                            width: 4em;
                        }
                    }
                }

                &__content{
                    .mrv__logos{
                        flex-direction: column;

                        img{
                           margin: 1rem; 
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) { 

                &__menu{
                    &__item{
                        width: 2.5em;
                        height: 2.5em;
                        padding-top: .6em;
                        &:after{
                            width: 2em;
                        }
                    }
                }

                &__content{
                    &__item{
                        h3{
                            margin-bottom: 1em;
                        }
                    }
                    .mrv__logos{
                        flex-direction: row;
                        margin-bottom: 2rem; 
                        img{
                           margin: 1rem; 
                        }
                    }
                }
            }

            @include media-breakpoint-down(xs) {         
                &__content{
                    &__item{
                        h3{
                            font-size: 1.6em;
                        }
                    }
                }

                &__menu{
                    &__item{
                        width: 2em;
                        height: 2em;
                        padding-top: .4em;
                        
                        &:after{
                            left: 1.3em;
                        }
                    }
                }
            }
        }
    }
}

.luggo-gradient{
    &--pink{
        background: linear-gradient(45deg, $color-pink-luggo-2, $color-pink-luggo-1);
    }
    &--yellow{
        background: linear-gradient(45deg, $color-yellow-luggo-2, $color-yellow-luggo-1);
    }
    &--purple{
        background: linear-gradient(45deg, $color-purple-luggo-4, $color-purple-luggo-2);
    }
}

.mrv-gradient{
    &--pink{
        background: linear-gradient(45deg, $color-pink-mrv-2, $color-pink-mrv-1);
    }
    &--green{
        background: linear-gradient(45deg, $color-green-mrv-2, $color-green-mrv-1);
    }
    &--yellow{
        background: linear-gradient(45deg, $color-yellow-mrv-2, $color-yellow-mrv-1);
    }
    &--purple{
        background: linear-gradient(45deg, $color-purple-mrv-2, $color-purple-mrv-1);
    }
}
