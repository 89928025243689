.gallery{
    &__usinas{

        a{
            position: relative;
            height: 100%;
            img{
                width: 100%;
                object-position: center;
                object-fit: fill;
            }
        }

        &__overlay{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            background-color: rgba($color-black, $alpha: 0.5);
        }
    }

    &__vantagens{
        img{
            width: 100%;
            object-position: center;
            object-fit: fill;
        }

        p{
            text-align: center;
        }
    }
}

.owl-dots{
    padding-top: 20px;
    text-align: center;

    .owl-dot{
        height: 20px;
        width: 20px;
        margin: 0 10px;
        border-radius: 50%;
        border:none !important;
        outline: none !important;
        background-color: $color-green;
        display: inline-block;
    }

    .owl-dot.active{
        background-color: $color-yellow !important;
    }
}

.na-midia{
    padding: 75px 0 50px 0;

    .owl-item{
        padding: 0 15px;
    }


    &__sol{
        display: inline-block;
        width: 60px;
        margin-right: 15px;
    }

    h2,h1{
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    &__item{
        display: block;
        position: relative;
        box-shadow: 5px 5px 0px 2px rgba($color-green, $alpha: 1);
        transition: .5s;
        margin: 20px 0;

        img{
            width: 100%;
            height: 200px;
            object-fit: cover;
        }

        &:hover{
            box-shadow: 7px 7px 0px 2px rgba($color-yellow, $alpha: 1);
           .na-midia__item{
               &__titulo{
                    left: -4px;
                    background-color: $color-yellow;


                    &:after{
                        border-left-color: $color-yellow; 
                        border-top-color: $color-yellow; 
                    }
                }
                &__texto{
                    p{
                        span{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &__titulo{

            position: absolute;
            top: 20px;
            text-align: left;
            left: -10px;
            transition: .5s;
            z-index: 2;
            max-width: 100%;
            background: $color-green;
            padding: 10px 2px 10px 10px;
            height: 40px;

            h5{
                color: $color-white;
                margin: auto;
                display: inline-block;
                text-transform: uppercase;
                width: auto;
                font-size: 15px;
                position: relative;
                font-weight: 900;
                transition: .5s;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;
            }

            &:after
            {
                content: "";
                display: inline-block;
                height: 100%;
                margin: 0 auto;
                border: 20px solid transparent;
                border-left-color: $color-green; 
                border-top-color: $color-green; 
                position: absolute;
                top: 0;
                right: -40px;
                bottom: 0;
                transition: .5s;

            }
        }

        &__texto{
            background-color: rgba($color-white, .75);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 10px 15px;

            p{
                color: $color-dark-gray;
                font-size: 14px;
                margin: 0;
                max-height: 50px;
                overflow: hidden;
                text-align: justify;
                line-height: 1.1;

                span{
                    color: $color-dark-green;
                }
            }
        }
    }
}