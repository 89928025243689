.termos{
    a{
        display: inline-block;
        margin: 0;
        color: $color-dark-green;

        &:hover{
            color: $color-yellow;
        }
    }

    li{
        font-size: 16px;
        p{
            margin: 0;
        }
    }

    p{
        text-align: justify;
    }
}